<template>
  <button class="gumb-veliki sekundarno" @click="odpriObrazec" v-if="!odprtObrazec">
    Pomoč
  </button>
  <div v-else class="obvestila polje prikaz">
    <div class="obvestilo-polje">
      <span>Naslov vprašanja</span>
      <input type="text" v-model="vprasanje.tematika">
      <span>Opis problema</span>
      <textarea v-model="vprasanje.vsebina"></textarea>
      <div class="vrstica">
        <button class="gumb primarno" @click="zapriObrazec"><span>Zapri</span></button>
        <button class="gumb nevarnost" @click="posljiVprasanje"><span>Pošlji vprašanje</span></button>
      </div>
    </div>

  </div>
  <div class="obvestila">
    <!-- Sprejeto vprašanje -->
    <div v-if="sprejeto" class="obvestilo-polje">
      <div class="polje">
        <div class="prikaz" @click="sprejeto=false">
          <h3 class="py-0.5">Poslano</h3>
        </div>
      </div>
    </div>

    <!-- Sprejeto vprašanje -->
    <div v-for="v in vprasanja" :key="v.id" class="obvestilo-polje">
      <div class="polje">
        <div class="prikaz mb-0">

          <h3 class="py-0.5">{{v.tematika}}</h3>
          <h5 class="py-0.5">{{v.odgovor}}</h5>
          <p class="py-0.5" v-html="v.vsebina"></p>
        </div>
        <div class="vrstica">
          <a v-if="v.url" :href="v.url" class="gumb-mali primarno">Poglej odgovor</a>
          <button @click="this.preberiOdgovor(v.id)" class="gumb-mali sekundarno">Označi kot prebrano</button>
        </div>
      </div>
    </div>
    <div v-for="v in obvestila" :key="v.id" class="obvestilo-polje">
      <div class="polje">
        <div class="prikaz mb-0">
          <h3 class="py-0.5">{{v.naslov}} ({{v.tekma}})</h3>
          <p class="py-0.5" v-html="v.vsebina"></p>
        </div>
        <div class="vrstica">
          <a v-if="v.url" :href="v.url" class="gumb-mali primarno">Poglej obvestilo</a>
          <button @click="this.preberiObvestilo(v.id)" class="gumb-mali sekundarno">Označi kot prebrano</button>
        </div>
      </div>

    </div>
    <div v-for="v in rezultati" :key="v.id" class="obvestilo-polje">
      <div class="polje">
        <div class="prikaz mb-0">

          <h3 class="py-0.5">{{v.naloga}}</h3>
          <h5 class="py-0.5" v-if="v.rezultat">{{v.rezultat}}</h5>
          <p class="py-0.5">{{v.status}}</p>
        </div>
        <div class="vrstica">
          <a v-if="v.url" :href="v.url" class="gumb-mali primarno">Poglej oddajo</a>
          <button @click="this.odstraniRezultat(v.id)" class="gumb-mali sekundarno">Označi kot prebrano</button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'App',
  data: function() {
    return {
      connection: null,
      vprasanja: [],
      obvestila: [],
      oddaje: [],
      rezultati: [],
      odprtObrazec: false,
      vprasanje: {
        tematika: '',
        vsebina: ''
      },
      sprejeto: false
    }
  },
  created: function() {
    this.connection = new WebSocket("wss://"+window.location.host+"/ws/uporabnik")
    const r = this
    this.connection.onmessage = function(event) {
      if (event.data) {
        const data = JSON.parse(event.data)
        if (data.obvestilo) {
          r.dodajObvestilo(data.obvestilo)
        }
        else if (data.rezultat) {
          r.dodajRezultat(data.rezultat)
        }
        else if(data.sprejeto) {
          r.sprejeto = true
          setTimeout(r.zapriSprejeto, 5000)
        }
        else if (data.odgovor) {
          r.dodajSporocilo(data.odgovor)
        }
        else {
          console.log(data)
        }
      }
    }

  },
  methods: {
    zapriSprejeto() {
      this.sprejeto = false
    },
    dodajSporocilo(sporocilo) {
      this.vprasanja.push(sporocilo)
    },
    dodajObvestilo(obvestilo) {
      this.obvestila.push(obvestilo)
    },
    dodajRezultat(rezultat) {
      this.rezultati.push(rezultat)
    },
    preberiOdgovor(vprasanje) {
      this.connection.send(JSON.stringify({
        'tip': 'preberi_odgovor',
        'id': vprasanje
      }))
      this.vprasanja = this.vprasanja.filter(v => {return v.id !== vprasanje})
    },
    preberiObvestilo(obvestilo) {
      this.connection.send(JSON.stringify({
        'tip': 'preberi_obvestilo',
        'id': obvestilo
      }))
      this.obvestila = this.obvestila.filter(v => {return v.id !== obvestilo})
    },
    odstraniRezultat(rezultat) {
      this.rezultati = this.rezultati.filter(v => {return v.id !== rezultat})
    },
    odstraniOddajo(oddaja) {
      this.oddaje = this.oddaje.filter(v => {return v.id !== oddaja})
    },
    odpriObrazec(){
      this.odprtObrazec = true
    },
    zapriObrazec(){
      this.odprtObrazec = false
    },
    posljiVprasanje() {
      this.connection.send(JSON.stringify({
        'tip': 'postavi_vprasanje',
        'vsebina': this.vprasanje.vsebina,
        'tematika': this.vprasanje.tematika,
        'url': document.URL
      }))
      this.odprtObrazec = false
    }
  }
}
</script>
